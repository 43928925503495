/* eslint-disable */
// prettier-ignore

import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
        {
      path: '/(\\d+)',
      name: 'refsignup',
      component: () => import('@/views/pages/Register.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/signup',
      name: 'signup',
      component: () => import('@/views/pages/Register.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/verify-login',
      name: 'verify-login',
      component: () => import('@/views/pages/VerifyLogin.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/verify-login/:id',
      name: 'verify-login',
      component: () => import('@/views/pages/VerifyLogin.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/reset-password',
      name: 'reset-password',
      component: () => import('@/views/pages/ResetPassword.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/forgot-password',
      name: 'forgot-password',
      component: () => import('@/views/pages/ForgotPassword.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/pages/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/confidence',
      name: 'confidence',
      component: () => import('@/views/pages/Confidence.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

if (localStorage.getItem('userData')) {
  const userData = JSON.parse(localStorage.getItem('userData'))

  if (userData.tmp && location.href.indexOf(userData.tmp) === -1) {
    location.href = `/${userData.tmp}/`
  }
}

router.beforeEach(async (to, from, next) => {
  if (!store.getters['app/isLoggedIn'] && localStorage.getItem('accessToken') && localStorage.getItem('userData')) {
    store.commit('app/SET_USER', JSON.parse(localStorage.getItem('userData')))
    store.commit('app/SET_LOGGED_IN', true)
  }

  if (!localStorage.getItem('accessToken') && to.matched[0] && to.matched[0].path !== '/login') {
    if (to.matched[0].path === '/signup') {
      next()
    } else if (to.matched[0].path === '/verify-login/:id') {
      next()
    } else if (to.matched[0].path === '/verify-login') {
      next()
    } else if (to.matched[0].path === '/(\\d+)') {
      next()
    } else if (to.matched[0].path === '/reset-password') {
      next()
    } else if (to.matched[0].path === '/confidence') {
      next()
    } else if (to.matched[0].path === '/forgot-password') {
      next()
    } else if (to.matched[0].path === '/report-form/:id') {
      next()
    } else if (to.matched[0].path === '/report-form/:id/:channel') {
      next()
    } else {
      next({
        path: '/login',
      })
    }
  }

  if (localStorage.getItem('accessToken') && to.matched[0] && to.matched[0].path === '/login') {
    next({
      path: '/',
    })
  }

  next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
