import axios from '@axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { $themeBreakpoints } from '@themeConfig'

export default {
  namespaced: true,
  state: {
    windowWidth: 0,
    shallShowOverlay: false,
    user: null,
    isLoggedIn: false,
    loading: false,
    loadingCreate: false,
    loadingUpdate: false,
  },
  getters: {
    user: state => state.user,
    loading: state => state.loading,
    loadingCreate: state => state.loadingCreate,
    loadingUpdate: state => state.loadingUpdate,
    isLoggedIn: state => state.isLoggedIn,
    currentBreakPoint: state => {
      const { windowWidth } = state
      if (windowWidth >= $themeBreakpoints.xl) return 'xl'
      if (windowWidth >= $themeBreakpoints.lg) return 'lg'
      if (windowWidth >= $themeBreakpoints.md) return 'md'
      if (windowWidth >= $themeBreakpoints.sm) return 'sm'
      return 'xs'
    },
  },
  mutations: {
    SET_USER(state, user) {
      state.user = user
    },
    SET_LOADING(state, loading) {
      state.loading = loading
    },
    SET_LOADING_CREATE(state, loadingCreate) {
      state.loadingCreate = loadingCreate
    },
    SET_LOADING_UPDATE(state, loadingUpdate) {
      state.loadingUpdate = loadingUpdate
    },
    SET_LOGGED_IN(state, flag) {
      state.isLoggedIn = flag
    },
    UPDATE_WINDOW_WIDTH(state, val) {
      state.windowWidth = val
    },
    TOGGLE_OVERLAY(state, val) {
      state.shallShowOverlay = val !== undefined ? val : !state.shallShowOverlay
    },
  },
  actions: {
    logout() {
      localStorage.removeItem('accessToken')
      localStorage.removeItem('refreshToken')
      localStorage.removeItem('userData')
      localStorage.removeItem('search-and-bookmark-data')
      document.location.reload()
    },
    showToastError(context, payload) {
      // eslint-disable-next-line no-underscore-dangle
      this._vm.$toast({
        component: ToastificationContent,
        position: 'top-center',
        props: {
          title: `Ошибка ${payload.status}`,
          icon: 'InfoIcon',
          variant: 'danger',
          text: payload.message,
        },
      })
    },
    async showSwalError(context, payload) {
      // eslint-disable-next-line no-underscore-dangle
      return this._vm.$swal({
        icon: 'error',
        html: payload.message,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    async fetchServiceData(context, payload) {
      return axios.get(`json/cp/service/data/list/${payload.stype}`)
    },
    async fetchData(context, payload) {
      const url = payload.apiId ? `${payload.api}/${payload.apiId}` : payload.api
      return axios.get(url, {
        params: payload && payload.params,
      })
    },
    async buyerWork(context, payload) {
      if (payload.stype === 'setBlock') {
        // eslint-disable-next-line no-underscore-dangle
        return this._vm.$swal({
          text: payload.xparam1 ? 'Заблокировать контракт?' : 'Разблокировать контракт?',
          icon: 'question',
          showCancelButton: true,
          confirmButtonText: 'Да',
          cancelButtonText: 'Нет',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
          showLoaderOnConfirm: true,
          preConfirm() {
            return axios.put(`json/cp/service/data/page/setblock/${payload.id_buyer}`, { xparam1: payload.xparam1 })
          },
        }).then(result => {
          if (result.isConfirmed) {
            const { data, success } = result.value.data
            if (data) {
              if (!success) {
                // eslint-disable-next-line no-underscore-dangle
                this._vm.$swal({
                  icon: 'error',
                  text: data.msg,
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                })
              } else {
                // eslint-disable-next-line no-underscore-dangle
                this._vm.$swal({
                  icon: 'success',
                  text: data.msg,
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
              }
            } else {
              // eslint-disable-next-line no-underscore-dangle
              this._vm.$swal({
                icon: 'error',
                text: 'Возникла непредвиденная ошибка. Попробуйте еще раз',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
              })
            }

            return true
          }

          return false
        }).catch(err => {
          const { data } = err.response.data

          // eslint-disable-next-line no-underscore-dangle
          this._vm.$swal({
            icon: 'error',
            html: data.msg,
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        })
      }

      if (payload.stype === 'sendEmail') {
        // eslint-disable-next-line no-underscore-dangle
        return this._vm.$swal({
          text: 'Отправить сообщение',
          icon: 'question',
          input: 'textarea',
          inputPlaceholder: 'Текст сообщения',
          showCancelButton: true,
          confirmButtonText: 'Отправить',
          cancelButtonText: 'Отмена',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
            input: 'mt-1',
          },
          buttonsStyling: false,
          showLoaderOnConfirm: true,
          preConfirm(message) {
            return axios.put(`json/cp/service/data/page/sendemail/${payload.id_buyer}`, { xparam1: message })
          },
        }).then(result => {
          if (result.isConfirmed) {
            const { data, success } = result.value.data
            if (data) {
              if (!success) {
                // eslint-disable-next-line no-underscore-dangle
                this._vm.$swal({
                  icon: 'error',
                  text: data.msg,
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                })
              } else {
                // eslint-disable-next-line no-underscore-dangle
                this._vm.$swal({
                  icon: 'success',
                  text: data.msg,
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
              }
            } else {
              // eslint-disable-next-line no-underscore-dangle
              this._vm.$swal({
                icon: 'error',
                text: 'Возникла непредвиденная ошибка. Попробуйте еще раз',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
              })
            }

            return true
          }

          return false
        }).catch(err => {
          const { data } = err.response.data

          // eslint-disable-next-line no-underscore-dangle
          this._vm.$swal({
            icon: 'error',
            html: data.msg,
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        })
      }

      return false
    },
    async uploadFile(context, payload) {
      const formData = new FormData()
      formData.append('file', payload.file)

      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
        onUploadProgress(e) {
          if (e.lengthComputable) {
            // eslint-disable-next-line prefer-template, no-console
            console.log(Math.round((e.loaded / e.total) * 100) + '%')
          }
        },
      }

      const res = await axios.post(
        'cp/file/upload',
        formData,
        config,
      )

      const { data } = res

      return data
    },
  },
}
